<router>
{
    name: 'InfoScan Dashboard'
}
</router>
<template lang="pug">
v-container(fluid)
</template>
<script>
import dashboard from "@/components/dashboard.vue";
export default {
    extends: dashboard,
    data () {
        return {
            cards: [],
        };
    },
    mounted () {
        window.infoscandashboardvm = this;
    },
};
</script>

<style scoped>
</style>
